<template>
  <div class="WorldRanking">
    <div class="top">
      <div class="__title">
        <span>官方世界高尔夫排名</span>
      </div>
      <div class="_select" v-if="false">
        <div class="_select_child">
          <div class="_select_title">年份</div>
          <div class="_data">
            <div class="_data_show" @click="isShow_year=!isShow_year">
              {{select_year}}
              <span class="iconfont icon-xiala1"></span>
            </div>
            <div class="select_data" v-show="isShow_year">
              <div class="_child"  @click="selectYear('2222')">666</div>
            </div>
          </div>
        </div>

        <div class="_select_child" >
          <div class="_select_title">时间周期</div>
          <div class="_data" >
            <div class="_data_show" @click="isShow_period=!isShow_period">
              {{select_period}}
              <span class="iconfont icon-xiala1"></span>
            </div>
            <div class="select_data" v-show="isShow_period">
              <div class="_child" @click="selectperiod('年代久远')">{{'年代久远'}}</div>
              <div class="_child" @click="selectperiod('只参加锦标赛')">{{'只参加锦标赛'}}</div>
            </div>
          </div>
        </div>

        <div class="_select_child">
          <div class="_select_title">锦标赛</div>
          <div class="_data" >
            <div class="_data_show" @click=" isShow_match=!isShow_match">
              {{match}}
              <span class="iconfont icon-xiala1"></span>
            </div>
            <div class="select_data" v-show="isShow_match">
              <div  class="_child" @click="selectMatch('中国巡回赛')">中国巡回赛</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    表格数据-->
    <div class="__list">
      <div class="__top" v-if="false">
        <span class="iconfont icon-sanjiaoyou"></span>
        <span>美巡平均：</span>
        <span>$10000</span>
      </div>
      <div class="_list">
        <div class="list_child">
          <div class="line01">本周排行</div>
<!--          <div class="line01">上周排行</div>-->
          <div class="line02">球员名称</div>
          <div class="line03">参赛次数</div>
          <div class="line04">平均积分</div>
          <div class="line04">总积分</div>
          <div class="line04">流失/失效积分</div>
          <div class="line04">新增积分</div>
<!--          <div class="line05">国家</div>-->
        </div>
        <div class="list_child"
             :class="{'bg':index%2===0}"
             v-for="(item, index) in list" :key="index">
          <div class="line01">{{index+1}}</div>
<!--          <div class="line01">{{item.curRankTied}}</div>-->
          <div class="line02 line02_other">
            {{item.player_name}}
            <span class="plaer_nameEn">{{item.e_name}}</span>
          </div>
          <div class="line03">{{item.statValues.rndEvents}}</div>
          <div class="line04">{{item.statValues.statValue1}}</div>
          <div class="line04">{{item.statValues.statValue2}}</div>
          <div class="line04">{{item.statValues.statValue3}}</div>
          <div class="line04">{{item.statValues.statValue4}}</div>
<!--          <div class="line05">{{ item.statValues.statValue3}}</div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import pgaApi from "../../../request/pgaApi";

  export default {
    name: "WorldRanking",
    data() {
      return {
        //  选择栏年份
        select_year:new Date().getFullYear(),
        isShow_year:false,
        //  选择栏的周期
        select_period:'只参加锦标赛',
        isShow_period:false,
        //  选择栏的锦标赛
        match:'本田经典',
        isShow_match:false,
        list:[]
      }
    },
    created() {
      this.getList()
    },
    methods: {
      //  选择栏年份事件
      selectYear(i){
        this.select_year = i
        this.isShow_year = false
      },
      //  选择栏周期事件
      selectperiod(i){
        this.select_period = i
        this.isShow_period = false
      },
      //  选择栏的锦标赛
      selectMatch(i){
        this.match = i
        this.isShow_match = false
      },
      async getList(){
        let data = await pgaApi({
          url:'/getPgaSta',
          data:{
            year:2023,
            staId:186
          }
        })
        if(data!==null){
          this.list = data.sta
          // console.log(this.list);
        } 
      
      }
    }
  }
</script>

<style scoped lang="less">
  .WorldRanking{
    width: 1225px;
    padding-top: 40px;
    .top{
      padding: 10px 10px 10px 30px;
      .__title{
        font-size: 30px;
        span{
          //font-family: My_Bold;
          font-weight: 500;
        }

      }

      ._select{
        margin-top: 15px;
        display: flex;
        ._select_child{
          cursor: pointer;
          margin-right: 50px;
          position: relative;
          ._select_title{
            font-size: 14px;
          }
          ._data_show{
            line-height: 30px;
            font-size: 16px;
            border-bottom: 1px solid #dddddd;
            position: relative;
            .icon-xiala1{
              position: absolute;
              right: 5px;
              font-size: 5px;
            }
          }
          .select_data{
            font-size: 16px;
            position: absolute;
            width: 100%;
            max-height: 300px;
            overflow: auto;
            border: 1px solid #88a6d7;
            ._child{
              padding-left: 5px;
              /*width: inherit;*/
              background-color: #ffffff;
            }
            ._child:hover{
              background-color: #1e90ff;
              color: #ffffff;
            }
          }
        }
        ._select_child:nth-child(1){
          width: 100px;

        }
        ._select_child:nth-child(2){
          width: 220px;
        }
        ._select_child:nth-child(3){
          width: 350px;
        }
      }
    }

    .__list{
      padding-top: 20px;
      padding-bottom: 20px;
      .__top{
        padding-left: 30px;
        font-size: 16px;
        padding-bottom: 20px;
        span{
          //font-family: My_Medium;
        }
        span:nth-child(2){
          //font-family: My_Medium;
          font-weight: 600;
        }
      }

      ._list{
        font-size: 16px;
        .list_child{
          display: flex;
          text-align: center;
          line-height: 50px;
          border: 1px solid #eeeeee;
          .line01{
            //font-family: My_Medium;
            width: 80px;
            border-right: 1px solid #eeeeee;
          }
          .line02{
            //font-family: My_Medium;;
            width: 400px;
            padding-left: 30px;
            text-align: left;
            border-right: 1px solid #eeeeee;
          }
          .line02_other{
            //font-family: My_Medium;
            color: #003e7e;
          }
          .line03{
            //font-family: My_Medium;
            width: 160px;
            border-right: 1px solid #eeeeee;
          }
          .line04{
            //font-family: My_Medium;
            width: 160px;
            border-right: 1px solid #eeeeee;
          }
          .line05{
            //font-family: My_Medium;
            width: 109px;
            /*border-right: 1px solid #000000;*/
          }
          .plaer_nameEn{
            color: #333333;
            font-weight: 600;
            margin-left: 10px;
          }
        }
        .list_child:nth-child(1){
          background-image: linear-gradient(#ffffff 25px, #ebebeb);
          div{
            //font-family: My_Medium;
          }
        }
        .bg{
          background-color: #f2f2f2;
        }
      }
    }
  }
</style>